import { Row } from "antd";

import { SvgIcon } from "../../common/SvgIcon";
import { HeaderSection, LogoContainer } from "./styles";

export default function Header() {
  return (
    <HeaderSection id="header">
      <Row justify="space-between">
        <LogoContainer to="/" aria-label="homepage">
          <SvgIcon src="logo.svg" width="190px" height="64px" />
        </LogoContainer>
      </Row>
    </HeaderSection>
  );
};
