import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: #1BBB8B;
  padding: 2.5rem 0;
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  margin-right: 1rem;

  &:hover,
  &:active,
  &:focus {
    color: #1BBB8B;
  }
`;

export const Extra = styled("section")`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const FooterContainer = styled("div")`
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover,
    &:active,
    &:focus {
      color: #1BBB8B99
    }
  }

  div {
    cursor: pointer;
  }

  @media screen and (max-width: 769px) {
    width: auto;
  }

`;
