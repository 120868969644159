import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderSection = styled("header")`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1bbb8b;
  padding-top: 5rem;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  justify-content: 'center';
  z-index: 1;
`;
