import { Row, Col } from "antd";
import { Slide } from "react-awesome-reveal";

import { IntroBlockSection, ContentWrapper, Content } from "./styles";
import { SvgIcon } from "../../common/SvgIcon";


export default function IntroBlock() {
  const StoreIcon = ({ href, src, styles }: { href: string, src: string, styles?: Object}) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={styles}
      >
        <SvgIcon src={src} width="30%" height="30%" />
      </a>
    );
  };

  return (
    <IntroBlockSection>
      <Slide direction="up" triggerOnce>
        <Row justify="center" align="middle">
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>

              <div style={{ width: '100%' }}>
                <h6 style={styles.text}>Seja alguém que</h6>
                <SvgIcon src='floating-menu.svg' width="90%" height="90%" />
                <h6 style={styles.text}>na sua vida financeira.</h6>
              </div>

              <Content>
                <p style={styles.download}>Baixe agora gratuitamente:</p>

                <div>
                  <StoreIcon
                    href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6499499789"
                    src="apple-store.svg"
                    styles={{ marginRight: '1rem' }}
                  />
                  <StoreIcon
                    href="https://"
                    src="google-play.svg"
                  />
                </div>
              </Content>
            </Col>
          </ContentWrapper>
        </Row>
      </Slide>
    </IntroBlockSection>
  );
};

const styles: { [key in string]: React.CSSProperties } = {
  text: {
    fontFamily: '"Manrope", sans-serif',
    fontWeight: '600',
    color: '#fff'
  },
  download: {
    fontFamily: '"Manrope", sans-serif',
    fontWeight: '400',
    color: '#fff'
  }
}