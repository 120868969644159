import styled from "styled-components";

export const IntroBlockSection = styled("section")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #1bbb8b;

  margin-top: -4rem;
  
  @media screen and (max-width: 1024px) {
    margin-top: -6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const Content = styled("p")`
  margin-top: 1rem;
  padding: 3rem 0 0;

  @media screen and (max-width: 1024px) {
    // margin-top: -6rem;
  }
`;

export const ContentWrapper = styled("div")`
  max-width: 640px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
