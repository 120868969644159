import styled from "styled-components";

export const FooterSection = styled("footer")`
  background: #1E1E1E;
  padding: 2.5rem 0;
`;

export const Title = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #1BBB8B;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Para = styled("div")`
  color: #FFFFFF;
  width: 70%;
  font-family: 'Manrope Light', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 96px
`;