import { Row, Col } from "antd";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  Para,
} from "./styles";

export default function PreFooter() {
  const scrollUp = () => {
    const element = document.getElementById("header") as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <>
      <FooterSection>
        <Container>
          <h6 style={styles.title}>Praticidade em planejar</h6>

          <Row justify="space-between">
            <Col lg={11} md={11} sm={24} xs={24} style={styles.square}>
              <Title>Já faz o seu planejamento financeiro?</Title>
              <Para>Melhore a sua experiência por meio do nosso app totalmente gratuito, sem cadastros e anúncios.</Para>

              <a onClick={scrollUp}>
                <div style={styles.button}>
                  <h6 style={styles.text}>Baixar app</h6>
                </div>
              </a>
            </Col>

            <Col lg={11} md={11} sm={24} xs={24} style={styles.square}>
              <Title>Que tal começar agora?</Title>
              <Para>Tenha controle do seu dinheiro de forma prática e eficaz. Digitou, salvou e pronto!</Para>


              <a onClick={scrollUp}>
                <div style={{ ...styles.button, backgroundColor: '#1BBB8B' }}>
                  <h6 style={{ ...styles.text, color: '#FFF' }}>Baixar app</h6>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

const styles: { [key in string]: React.CSSProperties } = {
  title: {
    fontFamily: '"Manrope", sans-serif',
    fontWeight: '500',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 36,
    marginBottom: 36,
  },
  square: {
    borderWidth: 1,
    borderColor: '#FFFFFF33',
    borderStyle: 'solid',
    borderRadius: 12,
    padding: '32px 72px 24px 32px',
    marginBottom: 12
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    width: '12rem',
    height: '3.5rem',
    borderRadius: 16,
    marginBottom: 0,
    position: 'absolute',
    bottom: '2rem'
  },
  text: {
    fontFamily: '"Manrope", sans-serif',
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 0,
    fontSize: 16,
  },
};
