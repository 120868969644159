import { Row, Col } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  FooterContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
  styles?: React.CSSProperties;
}

export default function Footer() {
  const SocialLink = ({ href, src, styles }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={styles}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={12} sm={24} xs={24}>
              <p style={styles.title}>Baixe agora gratuitamente:</p>
              <div style={{ margin: '1rem 0 1rem 0' }}>
                <a href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6499499789" target="_blank">
                  <SvgIcon src='apple-store.svg' width="30%" height="30%" styles={{ marginRight: '2rem' }} />
                </a>

                <a href="#" target="_blank">
                  <SvgIcon src='google-play.svg' width="30%" height="30%" />
                </a>
              </div>
            </Col>

            <Col lg={12} md={12} sm={24} xs={24}>
              <p style={styles.title}>Acompanhe nas redes sociais:</p>

              <div style={{ margin: '1rem 0 1rem 0' }}>

                <SocialLink
                  href="https://instagram.com/planeo.fintech"
                  src="instagram.svg"
                  styles={{ marginRight: '1rem' }}
                />
                <SocialLink
                  href="https://www.linkedin.com/company/35660365/admin/dashboard/"
                  src="linkedin.svg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </FooterSection>

      <Extra>
        <Container border={true}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Row justify="space-between" align="middle" style={{ paddingTop: "1rem" }}>
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                    src="logo-black.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                  />
                </LogoContainer>

                <p style={{...styles.text, marginTop: '-1rem'}}>© {new Date().getFullYear()} Planeo</p>
              </NavLink>

              <FooterContainer>
                <NavLink to="/terms">
                  <p style={styles.text}>Termos e condições</p>
                </NavLink>

                <NavLink to="/privacy">
                  <p style={styles.text}>Política de privacidade</p>
                </NavLink>
              </FooterContainer>
            </Row>
          </Col>
        </Container>
      </Extra>
    </>
  );
};


const styles: { [key in string]: React.CSSProperties } = {
  title: {
    fontFamily: '"Manrope", sans-serif',
    fontWeight: '500',
    color: '#fff',
    fontSize: 14,
    
  },
  text: {
    fontSize: 14,
    fontFamily: '"Manrope", sans-serif',
    fontWeight: '400',
  },
};
